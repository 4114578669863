import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import {
    AccountInfo,
    AuthenticationResult,
    InteractionRequiredAuthError,
    InteractionStatus,
    RedirectRequest,
    SilentRequest
} from '@azure/msal-browser';
import { Observable, of, Subject } from 'rxjs';
import {
    catchError, delay, filter, map, switchMap, take, tap
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AzureErrorCodes } from '../../enum';
import { apiConfig } from '../apiconfig';
import { TokenService } from './token.service';

@Injectable()
export class AuthService {
    private _authenticationTokenSubject$: Subject<void> = new Subject<void>();

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private _msalGuardConfig: MsalGuardConfiguration,
        private _tokenService: TokenService,
        private _msalService: MsalService,
        private _msalBroadcastService: MsalBroadcastService,
    ) {
        this._msalBroadcastService.inProgress$.pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            switchMap(() => this.getAuthenticationToken()),
        ).subscribe();
    }

    public isAuthenticated(): boolean {
        return Boolean(this._msalService.instance.getActiveAccount());
    }

    public loginRedirect(): Observable<void> {
        const request = { ...this._msalGuardConfig.authRequest } as RedirectRequest;

        return this._msalService.loginRedirect(request);
    }

    public logout(): void {
        this._logout().subscribe();
    }

    public unauthorize(): void {
        this._msalService.logout();
        this._tokenService.clearToken();
    }

    public getAuthenticationToken(): Observable<string> {
        this.checkAndSetActiveAccount();
        const params: SilentRequest = {
            account: this._msalService.instance.getActiveAccount(),
            scopes: apiConfig.b2cScopes,
        };

        return this._msalService.acquireTokenSilent(params).pipe(
            switchMap((response: AuthenticationResult) => {
                if (new Date(response.expiresOn) > new Date()) {
                    this._tokenService.token = response.accessToken;
                    this._authenticationTokenSubject$.next();

                    return response.accessToken;
                }

                throw new Error('Token is expired');
            }),
            catchError((error: HttpErrorResponse) => {
                if (error instanceof InteractionRequiredAuthError) {
                    if (error.errorMessage.includes(AzureErrorCodes.SessionNotExist)) {
                        return this._logout().pipe(map(() => ''));
                    }

                    if (error.errorMessage.includes('interaction_required')) {
                        return this._msalService.acquireTokenRedirect(params).pipe(map(() => ''));
                    }

                    if (error.errorCode !== 'block_token_requests' &&
                        error.errorCode !== 'user_cancelled' &&
                        error.errorCode !== 'login_progress_error') {
                        throw error;
                    }
                } else if (error.message === 'Token is expired') {
                    return this.loginRedirect().pipe(map(() => ''));
                } else {
                    return this._msalService.acquireTokenRedirect(params).pipe(map(() => ''));
                }

                return of('');
            }),
        );
    }

    public checkAndSetActiveAccount(): void {
        const account = this._msalService.instance.getAllAccounts().find(
            (item: AccountInfo) => item.environment === environment.authorityName,
        );

        if (account) {
            this._msalService.instance.setActiveAccount(account);
        }
    }

    public waitForAuthenticationToken(): Observable<void> {
        return this._authenticationTokenSubject$.asObservable().pipe(take(1));
    }

    private _logout(): Observable<void> {
        const delayTime = 2000;

        return this._msalService.logout({
            extraQueryParameters: {
                ['client_id']: environment.msalClientId,
            },
        }).pipe(
            tap(() => this._tokenService.clearToken()),
            delay(delayTime),
            switchMap(() => this.loginRedirect()),
        );
    }
}
