<ng-container #cdkOverlayOrigin="cdkOverlayOrigin" cdkOverlayOrigin>
    <div #icon>
        <ng-content select="[custom-icon]"></ng-content>
    </div>
</ng-container>

<ng-template #overlayTemplate cdkConnectedOverlay [cdkConnectedOverlayOrigin]="cdkOverlayOrigin">
    <div class="info-popup">
        <ng-content></ng-content>
    </div>
</ng-template>