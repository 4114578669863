import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
    debounceTime, filter,
    take, takeUntil,
    tap
} from 'rxjs/operators';
import { POPUP_OVERLAY_POSITION_RIGHT } from '../constants';

@Component({
    selector: 'app-popup-on-hover',
    styleUrls: ['./popup-on-hover.component.scss'],
    templateUrl: './popup-on-hover.component.html',
})
export class PopupOnHoverComponent implements AfterViewInit, OnDestroy {
    @Input() public overlayPositions: ConnectedPosition[] = POPUP_OVERLAY_POSITION_RIGHT;

    @ViewChild('overlayTemplate', { static: false }) private _overlayTemplate!: TemplateRef<unknown>;

    @ViewChild('icon', { static: false }) private _icon!: ElementRef<HTMLElement>;

    private _currentMouseEnterSubscription?: Subscription;

    private _currentMouseLeaveSubscription?: Subscription;

    private _overlayRef!: OverlayRef;

    private _destroy$: Subject<void> = new Subject<void>();

    private _isMouseOutsidePopup: boolean = true;

    private _isMouseOverIcon: boolean = false;

    private readonly _debounceTime: number = 150;

    constructor(
        private _overlay: Overlay,
        private _cdr: ChangeDetectorRef,
        private _viewContainerRef: ViewContainerRef,
    ) {
    }

    public ngAfterViewInit(): void {
        this._createOverlay();
        this._addHoverListeners();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();

        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    private _createOverlay(): void {
        const positionStrategy = this._overlay.position()
            .flexibleConnectedTo(this._icon)
            .withPositions(this.overlayPositions);

        const overlayConfig = new OverlayConfig({
            positionStrategy: positionStrategy,
        });

        this._overlayRef = this._overlay.create(overlayConfig);
    }

    private _addHoverListeners(): void {
        const iconElement = this._icon.nativeElement;

        const mouseEnterIcon$ = fromEvent(iconElement, 'mouseenter');
        const mouseLeaveIcon$ = fromEvent(iconElement, 'mouseleave');

        mouseEnterIcon$
            .pipe(
                tap(() => this._isMouseOverIcon = true),
                debounceTime(this._debounceTime),
                takeUntil(this._destroy$),
                filter(() => this._isMouseOverIcon),
            ).subscribe(() => this._openOverlay());

        mouseLeaveIcon$
            .pipe(
                tap(() => this._isMouseOverIcon = false),
                debounceTime(this._debounceTime),
                takeUntil(this._destroy$),
                filter(() => this._isMouseOutsidePopup),
            ).subscribe(() => this._closeOverlay());
    }

    private _openOverlay(): void {
        if (this._overlayRef.hasAttached()) {
            return;
        }

        const templatePortal = new TemplatePortal(this._overlayTemplate, this._viewContainerRef);

        this._overlayRef.attach(templatePortal);
        this._cdr.detectChanges();

        [this._currentMouseLeaveSubscription, this._currentMouseEnterSubscription]
            .forEach((subscription: Subscription | undefined) => subscription?.unsubscribe());

        const mouseEnterOverlay$ = fromEvent(this._overlayRef.overlayElement, 'mouseenter');
        const mouseLeaveOverlay$ = fromEvent(this._overlayRef.overlayElement, 'mouseleave');

        this._currentMouseEnterSubscription = mouseEnterOverlay$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this._isMouseOutsidePopup = false);

        this._currentMouseLeaveSubscription = mouseLeaveOverlay$
            .pipe(take(1), debounceTime(this._debounceTime))
            .subscribe(() => {
                this._isMouseOutsidePopup = true;
                this._closeOverlay();
            });
    }

    private _closeOverlay(): void {
        if (this._overlayRef.hasAttached()) {
            this._overlayRef.detach();
        }
    }
}
