import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDialog } from '../base-dialog/base-dialog.component';
import { AlertType } from '../enums';

export interface IAlertDialogOptions {
    buttonLabel?: string;
    message?: string;
    title?: string;
    type?: AlertType;
}

export const DEFAULT_ALERT_OPTIONS: IAlertDialogOptions = {
    buttonLabel: 'Ok',
    message: '',
    title: 'Error',
};

@Component({
    selector: 'app-alert-dialog',
    styleUrls: ['./alert-dialog.component.scss'],
    templateUrl: './alert-dialog.component.html',
})
export class AlertDialogComponent extends BaseDialog<IAlertDialogOptions> {
    public set options(options: IAlertDialogOptions) {
        this.options.buttonLabel = options.buttonLabel ? options.buttonLabel : DEFAULT_ALERT_OPTIONS.buttonLabel;
        this.options.message = options.message ? options.message : DEFAULT_ALERT_OPTIONS.message;
        this.options.title = options.title ? options.title : DEFAULT_ALERT_OPTIONS.title;
        this.options.type = options.type || null;
    }

    public get options(): IAlertDialogOptions {
        return this._options;
    }

    private _options: IAlertDialogOptions = DEFAULT_ALERT_OPTIONS;

    constructor(
        dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) options: IAlertDialogOptions,
    ) {
        super(dialogRef);
        this.options = options;
    }

    public getMessage(): string {
        let iconClass: string;

        switch (this.options.type) {
            case AlertType.Success:
                iconClass = 'icon-check-circle green';
                break;
            case AlertType.Error:
                iconClass = 'icon-cross-circle red';
                break;
            case AlertType.Notification:
                iconClass = 'icon-warning orange';
                break;
            default:
                iconClass = null;
        }

        if (!iconClass) {
            return this.options.message;
        }

        return `<span class="icon ${iconClass} message-icon"></span>${this.options.message}`;
    }
}
