import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent, IConfirmDialogConfig } from './../../layout/pecan';

@Injectable()
export class MatDialogService {
    constructor(
        private _matDialog: MatDialog,
        private _translate: TranslateService,
    ) { }

    public open<T, D = unknown, R = unknown>(
        component: ComponentType<T>,
        config?: MatDialogConfig<D>,
    ): MatDialogRef<T, R> {
        return this._matDialog.open(component, config);
    }

    public confirm(config: IConfirmDialogConfig): Observable<boolean> {
        return this.open<ConfirmDialogComponent, IConfirmDialogConfig, boolean>(ConfirmDialogComponent, {
            data: {
                content: config.content,
                header: config.header ?? this._translate.instant('confirmation'),
                title: config.title,
            },
            panelClass: config.panelClass ?? 'confirmation-dialog',
        }).afterClosed();
    }
}