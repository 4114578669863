{
    "name": "pecan-cloud-ui",
    "version": "0.46.1",
    "description": "pecan cloud",
    "scripts": {
        "ng": "ng",
        "start": "ng serve",
        "start:prod": "ng serve --configuration production",
        "start:staging": "ng serve --configuration staging",
        "start:dev": "ng serve --configuration development",
        "start:local": "ng serve --configuration local",
        "build:prod": "ng build --configuration production",
        "build:staging": "ng build --configuration staging",
        "build:dev": "ng build --configuration development",
        "build:local": "ng build --configuration local",
        "build": "ng build",
        "test": "jest",
        "test:pact": "jest --config ./jest.config.pact.js",
        "lint": "ng lint",
        "e2e": "ng e2e"
    },
    "keywords": [
        "cloud"
    ],
    "author": "Pecan Solutions",
    "license": "ISC",
    "private": true,
    "dependencies": {
        "@angular/animations": "^18.2.6",
        "@angular/cdk": "~18.2.6",
        "@angular/common": "^18.2.6",
        "@angular/compiler": "^18.2.6",
        "@angular/core": "^18.2.6",
        "@angular/forms": "^18.2.6",
        "@angular/material": "^18.2.6",
        "@angular/platform-browser": "^18.2.6",
        "@angular/platform-browser-dynamic": "^18.2.6",
        "@angular/router": "^18.2.6",
        "@azure/msal-angular": "^3.0.24",
        "@azure/msal-browser": "^3.24.0",
        "@ngx-translate/core": "^14.0.0",
        "@ngx-translate/http-loader": "^7.0.0",
        "angular-split": "^13.2.0",
        "moment": "^2.29.4",
        "ngx-currency": "^18.0.0",
        "ngx-infinite-scroll": "^18.0.0",
        "ngx-mask": "^18.0.0",
        "rxjs": "~7.5.0",
        "tslib": "^2.3.0",
        "uuid": "^9.0.1",
        "zone.js": "~0.14.10"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^18.2.6",
        "@angular-eslint/builder": "^18.3.1",
        "@angular-eslint/eslint-plugin": "^18.3.1",
        "@angular-eslint/eslint-plugin-template": "^18.3.1",
        "@angular-eslint/schematics": "^18.3.1",
        "@angular-eslint/template-parser": "^18.3.1",
        "@angular/cli": "~18.2.6",
        "@angular/compiler-cli": "^18.2.6",
        "@pact-foundation/pact": "^12.5.2",
        "@types/jest": "^29.2.2",
        "@types/lodash": "^4.14.118",
        "@typescript-eslint/eslint-plugin": "^5.43.0",
        "@typescript-eslint/parser": "^5.43.0",
        "eslint": "^8.28.0",
        "eslint-plugin-import": "^2.26.0",
        "eslint-plugin-rxjs": "^5.0.2",
        "jest": "^29.6.1",
        "jest-preset-angular": "^14.2.4",
        "lodash": "^4.17.15",
        "ngx-trim-directive": "^2.0.0",
        "typescript": "~5.4.5"
    },
    "optionalDependencies": {
        "@rollup/rollup-linux-x64-gnu": "^4.22.4"
    }
}
