import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDialog } from '../base-dialog/base-dialog.component';
import { IConfirmDialogConfig } from '../confirm-dialog-config.interface';

@Component({
    selector: 'app-confirm-dialog',
    styleUrls: ['./confirm-dialog.component.scss'],
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends BaseDialog<void> {
    constructor(
        @Inject(MAT_DIALOG_DATA) public context: IConfirmDialogConfig,
        dialogRef: MatDialogRef<ConfirmDialogComponent>,
    ) {
        super(dialogRef);
    }
}
